@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Timeout slider */
.slider-container {
  position: relative;
  margin-top: 10px;
}

datalist {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

datalist option {
  width: 20%;
}
/* Login show or hide password */
.password-input-group {
  position: relative;
}

.password-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.password-input {
  padding-right: 40px; /* Adjust this value to accommodate the icon */
}

.show-hide-icon {
  position: absolute;
  top: 30%;
  right: 10px; /* Adjust this value to position the icon */
  transform: translateY(-50%);
  cursor: pointer;
  color: black;
}